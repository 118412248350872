import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Buefy)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false
    //Vue.prototype.$backendURL = "http://localhost:8000/api/"
Vue.prototype.$storageURL = "https://wunderlinie.quantumfrog.de"

Vue.prototype.$backendURL = "https://wunderlinie.quantumfrog.de/public/api/"




new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')