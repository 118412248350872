<template>
<div class="component">

    <div class="columns is-mobile headerrow">
        <div class="column is-6 is-offset-3">
            <div class="button is-primary is-large" @click="newTown=true">Neue Stadt</div>
        </div>
    </div>

    <div>
        <div v-for="town in allTowns" class="columns is-mobile is-multiline">
            <div class="column is-1"><img v-if="town.PromoImageURL.length>5" class="previewImage" :src="$storageURL + town.PromoImageURL"></div>
            <div class="column is-3 is-size-4">{{town.TownName}}</div>
            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openEditModal(town)">Bearbeiten</div>
            </div>
            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openTours(town)">Routen</div>
            </div>
            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openTownPOIs(town)">POIs</div>
            </div>
            <div class="column is-2 is-size-4">
                <div class="button is-large is-danger" @click="openDeleteWindow(town)">Löschen</div>
            </div>
        </div>

    </div>

    <div class="modal" :class="{'is-active':newTown}">
        <div class=" modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Neue Stadt</p>
                <button class="delete" aria-label="close" @click="closeModal();"></button>
            </header>
            <section class="modal-card-body">

                <div class="columns is-mobile is-multiline">
                    <div class="column is-3">Stadtname</div>
                    <div class="column is-9"><input v-model="newTownName" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Kurzbeschreibung</div>
                    <div class="column is-9"><input v-model="newTownShortDescription" class="input is-primary" type="text" placeholder=""></div>

                                 <div class="column is-3">NotificationDistance</div>
            <div class="column is-9"><input v-model="NotificationDistance" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Latidtude</div>
                    <div class="column is-9"><input v-model="newTownLatidtude" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Longitude</div>
                    <div class="column is-9"><input v-model="newTownLongitude" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">TrainStop</div>
                    <div class="column is-9"><input v-model="TrainStop" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">ConnectionTrainStop</div>
                    <div class="column is-9"><input v-model="ConnectionTrainStop" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Sprache</div>
                    <div class="column is-9"><input v-model="newTownLanguage" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-12"> <label class="checkbox">
                            <input v-model="HasTrainStation" type="checkbox">
                            Besitzt Bahnhof
                        </label></div>

                    <div v-if="isInEditMode">
                        <div class="column is-3">Media</div>
                        <div class="column is-9"><input type="file" class="form-control" v-on:change="onChange"></div>

                    </div>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="createTown()">Speichern</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>
    <deleteconfirmation :isopen="openDeleteConfirmation" @close="closeDeleteWindow" @confirmdelete="deleteTown" />
</div>
</template>

<script>
import deleteconfirmation from '@/components/confirmdelete.vue'

export default {
    name: 'Town',
    props: [],
    components: {
        deleteconfirmation
    },
    mounted: function () {
        this.getAllTowns();
    },
    data() {
        return {
            newTown: false,
            allTowns: [],
            isInEditMode: "",
            newTownName: "",
            newTownShortDescription: "",
            newTownPostalCode: "",
            newTownLongitude: "",
            newTownLatidtude: "",
            townToEdit: 0,
            file: '',
            newTownLanguage: "de",
            HasTrainStation: true,
            openDeleteConfirmation: false,
            townToDelete: null,
            NotificationDistance:"",
            ConnectionTrainStop:"",
            TrainStop:"",

        }
    },
    methods: {
        onChange(e) {
            this.file = e.target.files[0];
        },
        closeModal() {
            this.newTown = false;
            this.isInEditMode = false;
            this.townToEdit = null;
            this.newTownName = ""
            this.newTownShortDescription = ""
            this.newTownPostalCode = ""
            this.newTownLongitude = ""
            this.newTownLatidtude = ""
            this.newTownLanguage = "de"
            this.hasTrainStation = true;
            this.NotificationDistance = "";
            this.ConnectionTrainStop = ""
            this.TrainStop = ""
        },
        openEditModal(town) {
            this.newTownName = town.TownName
            this.newTownShortDescription = town.ShortDescription
            this.newTownPostalCode = town.PostalCode
            this.newTownLongitude = town.Longitude
            this.newTownLatidtude = town.Latidtude
            this.townToEdit = town.id
            this.isInEditMode = true;
            this.newTown = true;
            this.newTownLanguage = town.Language
            this.HasTrainStation = town.HasTrainStation;
            this.NotificationDistance = town.NotificationDistance
            this.ConnectionTrainStop = town.ConnectionTrainStop
            this.TrainStop = town.TrainStop
        },
        saveImage(id) {
            let data = new FormData();
            data.append('file', this.file);
            data.append('townId', id);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "town/changeImage", data, config).then((response) => {
                console.log(response)
                this.closeModal();
                this.getAllTours();
            })

        },
        getAllTowns() {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            this.axios.get(this.$backendURL + "town/all", config).then((response) => {
                console.log(response)
                this.allTowns = response.data.towns
            })
        },
        openDeleteWindow(town) {
            this.townToDelete = town;
            this.openDeleteConfirmation = true;
        },
        closeDeleteWindow() {
            this.townToDelete = null;
            this.openDeleteConfirmation = false;
        },
        deleteTown() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'id': this.townToDelete.id,

            }
            this.axios.post(this.$backendURL + "town/delete", apidata, config).then((response) => {
                this.closeDeleteWindow();
                this.getAllTowns();
            })
        },
        openTours(town) {
            this.$router.push({
                name: 'Tours',
                params: {
                    townId: town.id
                }
            })

        },

        openTownPOIs(townObject) {
            this.$router.push({
                name: 'TownPOIs',
                params: {
                    town: townObject
                }
            })

        },

        createTown() {
            if (this.newTownName == "") {
                return;
            }
            let newTownData = {
                'TownName': this.newTownName,
                'ShortDescription': this.newTownShortDescription,
'NotificationDistance':this.NotificationDistance,
                'PostalCode':"",
                'Latidtude': this.newTownLatidtude,
                'Longitude': this.newTownLongitude,
                'HasTrainStation': this.HasTrainStation,
                'Language': this.newTownLanguage,
                'ConnectionTrainStop':this.ConnectionTrainStop,
                'TrainStop':this.TrainStop,

            }
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            if (this.isInEditMode) {
                newTownData.id = this.townToEdit;
                this.axios.post(this.$backendURL + "town/edit", newTownData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.town.id)

                    } else {
                        this.closeModal();
                        this.getAllTowns();
                    }
                })
            } else {
                this.axios.post(this.$backendURL + "town/create", newTownData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.town.id)

                    } else {
                        this.closeModal();
                        this.getAllTowns();
                    }
                })
            }

        }
    },
    computed: {},
}
</script>

<style scoped>
.previewImage {
    max-height: 4rem;
}
</style>
