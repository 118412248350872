<template>
<div id="app">
    <div id="nav" v-if="false">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
    </div>
    <router-view />
</div>
</template>

<script>
export default {
    name: 'wunderlinie',
    props: [],
    mounted: function () {

        console.log("regrouter")

        this.$router.beforeEach((to, from, next) => {

            if (to.name == "Login" || to.name == "login" || to.name == "Register" || to.name == "POIDetails" || to.name == "POIs") {
                next();
            } else {
                if (localStorage.getItem('bearertoken') == null) {
                    next({
                        path: '/login',
                    })
                } else {

                    this.axios.get(this.$backendURL + "auth/user", {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.bearertoken
                        }
                    }).then((response) => {
                        console.log("loggedin")
                        console.log(response.data)
                        next();

                    }).catch((error) => {
                        next({
                            path: '/login',
                        })
                    })

                }
            }

        })
    },
    data() {
        return {
            data: []
        }
    },
    methods: {},
    computed: {},
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top:20px;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.headerrow {
    margin-bottom: 6rem !important;
    margin-top: 2rem !important;
    border-bottom: 1px solid rgba(200,200,200,0.8)
}
</style>
