<template>
    <div class="component">
    <div class="modal" :class="{'is-active':isopen}">
        <div class=" modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Löschen</p>
                <button class="delete" aria-label="close" @click="closeDialog();"></button>
            </header>
            <section class="modal-card-body">

               <div>Möchten Sie wirklich alle Inhalte unwiederuflich entfernen? Bitte geben Sie in das untenstehende Feld "Löschen" ein und bestätigen Sie ihr Vorhaben.</div>
                   <div class="column is-9"><input v-model="deletemessage" class="input is-primary" type="text" placeholder=""></div>

            </section>
            <footer class="modal-card-foot">
                <button v-if="deletemessage=='Löschen'" class="button is-success" @click="confirmDeletion()">Löschen</button>
                <button class="button" @click="closeDialog()">Abbrechen</button>
            </footer>
        </div>
    </div>

    </div>
</template>

<script>
    export default {
        name: 'component',
        props: ['isopen'],
        mounted: function () {
        },
        data() {
            return {
                msg: 'Welcome to Your Vue.js App',
                data: [],
                deletemessage:""
            }
        },
        methods: {

            closeDialog(){
                this.deletemessage = "";
                this.$emit('close')

            },

            confirmDeletion(){

                this.deletemessage = "";
                this.$emit('confirmdelete')

            }
        },
        computed: {},
    }
</script>

<style scoped>

</style>
