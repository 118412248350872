<template>
<div class="component">
    <div class="columns is-mobile">
        <div class="column is-2">
            <div class="button is-primary is-large" @click="closePOI()">Zurück</div>
        </div>
        <div class="column is-8">
            <div class="title" v-if="poi!=null">{{poi.Title}}</div>
        </div>
    </div>
    <div class="poiDetails" v-if="poi!=null">
        <div class="columns is-mobile is-multiline">
            <div class="column is-3">Titel</div>
            <div class="column is-9"><input v-model="Title" class="input is-primary" type="text" placeholder=""></div>

            <div class="column is-3">Typ</div>
            <div class="column is-9">

                <div class="dropdown " @click="openTypeDropdown=!openTypeDropdown" :class="{'is-active':openTypeDropdown}">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span>{{typeDropdownName}}</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">

                            <a href="#" @click="changeType(1)" :class="{'is-active':Type==1}" class="dropdown-item">
                                Wunder
                            </a>
                            <a href="#" @click="changeType(2)" :class="{'is-active':Type==2}" class="dropdown-item ">
                                Quiz
                            </a>
                            <a href="#" @click="changeType(3)" :class="{'is-active':Type==3}" class="dropdown-item ">
                                Standard
                            </a>
                               <a href="#" @click="changeType(4)" :class="{'is-active':Type==4}" class="dropdown-item ">
                                Routenpunkt
                            </a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="column is-3">ComplitionCondition</div>
            <div class="column is-9">

                <div class="dropdown " @click="openCompletionDropdown=!openCompletionDropdown" :class="{'is-active':openCompletionDropdown}">
                    <div class="dropdown-trigger">
                        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                            <span>{{complDropdownName}}</span>
                            <span class="icon is-small">
                                <i class="fas fa-angle-down" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content">

                            <a href="#" @click="changeCompletionCondition(0)" :class="{'is-active':ComplitionCondition==0}" class="dropdown-item">
                                Visited
                            </a>
                            <a href="#" @click="changeCompletionCondition(1)" :class="{'is-active':ComplitionCondition==1}" class="dropdown-item">
                                Tapped
                            </a>
                            <a href="#" @click="changeCompletionCondition(2)" :class="{'is-active':ComplitionCondition==2}" class="dropdown-item">
                                Viewed
                            </a>
                            <a href="#" @click="changeCompletionCondition(3)" :class="{'is-active':ComplitionCondition==3}" class="dropdown-item">
                                Completed
                            </a>

                        </div>
                    </div>
                </div>

            </div>

            <div class="column is-3">Latitude</div>
            <div class="column is-9"><input v-model=" poiLatidtude" class="input is-primary" type="text" placeholder=""></div>

            <div class="column is-3">Longitude</div>
            <div class="column is-9"><input v-model="poiLongitude" class="input is-primary" type="text" placeholder=""></div>

     <div class="column is-3">Index</div>
            <div class="column is-9"><input v-model="poiIndex" class="input is-primary" type="number" placeholder=""></div>

            <div class="column is-3" v-if="Type!=2 && Type != 3  &&Type!=4">NotificationDistance</div>
            <div class="column is-9" v-if="Type!=2 && Type != 3  &&Type!=4"><input v-model="NotificationDistance" class="input is-primary" type="text" placeholder=""></div>



  <div class="column is-3" >Sprechblasen Text</div>
            <div class="column is-9" ><textarea class="textarea"  v-model="TextBubble"></textarea>
</div>









            <div class="column is-12">
                <div class="button is-primary" @click="updatePOI()">Speichern</div>
            </div>
            <div class="column is-3" v-if="Type!=2 && Type != 3 &&Type!=4">CustomObjectIOs</div>
            <div class="column is-5" v-if="Type!=2 && Type != 3 &&Type!=4"><a v-if="this.CustomObjectIOsURL && this.CustomObjectIOsURL.length>5" target="_blank" :href="$storageURL + this.CustomObjectIOsURL">{{this.CustomObjectIOsURL}}</a></div>
            <div class="column is-4" v-if="Type!=2 && Type != 3 &&Type!=4"><input type="file" class="form-control" v-on:change="onChangeIOSFile"></div>

            <div class="column is-3" v-if="Type!=2 && Type != 3 &&Type!=4">CustomObjectAndroid</div>
            <div class="column is-5" v-if="Type!=2 && Type != 3 &&Type!=4"><a v-if="this.CustomObjectAndroidURL &&this.CustomObjectAndroidURL.length>5" target="_blank" :href="$storageURL + this.CustomObjectAndroidURL">{{this.CustomObjectAndroidURL}}</a></div>
            <div class="column is-4" v-if="Type!=2 && Type != 3 &&Type!=4"><input type="file" class="form-control" v-on:change="onChangeAndroidFile"></div>

            <div class="column is-3" v-if="Type!=1 && Type != 2 &&Type!=4">Video</div>
            <div class="column is-5" v-if="Type!=1 && Type != 2 &&Type!=4"><a v-if="this.VideoURL &&this.VideoURL.length>5" target="_blank" :href="$storageURL + this.VideoURL">{{this.VideoURL}}</a></div>
            <div class="column is-4" v-if="Type!=1 && Type != 2 &&Type!=4"><input type="file" class="form-control" v-on:change="onChangeVideoFile"></div>

            <div class="column is-12" v-if=" Type!=4" >
                <div class="button is-primary" @click="openPOIContentList()">POIContent</div>
            </div>

        </div>

        <div class="quizUI" v-if="Type!=1 && Type != 3  &&Type!=4">
            <div class="addQuizz">
                <div class="button is-primary" v-if="!newQuiz" @click="createNewQuiz()">Neue Quizfrage</div>

                <div class="newQuizFields columns is-mobile is-multiline" v-if="newQuiz">
                    <div class="column is-3">Question</div>
                    <div class="column is-9"><input v-model="newQuizQuestion" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Antworten </div>
                    <div class="column is-9"><input v-model="newQuizPossibleAnswers" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Antwortindex</div>
                    <div class="column is-9"><input v-model="newQuizCorrectAnswer" class="input is-primary" type="number" placeholder=""></div>

                    <div class="column is-12">
                        <div class="button is-primary" @click="saveQuiz()">Speichern</div>
                        <div class="button is-danger" @click="cancelNewQuiz()">Abbrechen</div>
                    </div>

                </div>
            </div>

            <div v-for="quiz in Quizzes" class="columns is-mobile is-multiline">
                <div class="column is-3">{{quiz.Question}}</div>
                <div class="column is-7">{{parseQuizAnswers(quiz.PossibleAnswers)}}</div>
                <div class="column is-1">{{quiz.CorrectAnswerIndex}}</div>
                <div class="column is-1">
                    <div class="button is-danger" @click="deleteQuiz(quiz.id)">Löschen</div>
                </div>

            </div>

        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'POI',
    props: [],
    mounted: function () {
        if (!this.$route.params.poi) {
            this.$router.push({
                name: 'Towns'
            })

        }
        this.tour = this.$route.params.tour
        this.poi = this.$route.params.poi
        this.Title = this.poi.Title
        this.Type = this.poi.Type;
        this.ComplitionCondition = this.poi.ComplitionCondition;
        this.TextList = this.poi.TextList;
        this.ImageURLsList = this.poi.ImageURLsList;
        this.VideoURL = this.poi.VideoURL;
        this.CustomObjectIOsURL = this.poi.CustomObjectIOsURL;
        this.CustomObjectAndroidURL = this.poi.CustomObjectAndroidURL;
        this.poiToEdit = this.poi.id;
        this.poiLatidtude = this.poi.Latidtude
        this.poiLongitude = this.poi.Longitude
        this.poiIndex = this.poi.POIIndex
        this.town = this.$route.params.town
        this.NotificationDistance = this.poi.NotificationDistance
        this.TextBubble = this.poi.TextBubble
        this.changeComplDropdown(this.ComplitionCondition)
        this.changeTypeDropdown(this.Type)
        this.getQuizzes();

    },
    data() {
        return {
            poi: null,
            Title: "",
            Type: 2,
            ComplitionCondition: 1,
            TextList: "",
            ImageURLsList: "",
            VideoURL: "",
            CustomObjectIOsURL: "",
            CustomObjectAndroidURL: "",
            CustomIosFile: null,
            CustomAndroidFile: null,
            poiToEdit: 0,
            VideoFile: null,
            VideoFileURL: "",
            Quizzes: [],
            newQuiz: false,
            newQuizQuestion: "",
            newQuizPossibleAnswers: [],
            newQuizCorrectAnswer: 0,
            poiLongitude: 0,
            poiLatidtude: 0,
            tour: null,
            town: null,
            openTypeDropdown: false,
            openCompletionDropdown: false,
            NotificationDistance: "",
            typeDropdownName: "Typ auswählen",
            complDropdownName: "Vorraussetzung",
            TextBubble:"",
            poiIndex:0,
        }
    },
    methods: {
        openPOIContentList() {
            this.$router.push({
                name: 'POIContent',
                params: {
                    poi: this.poi,
                    tour: this.tour,
                    town: this.town
                }
            })

        },
        loadPOIs() {

        },
        createNewQuiz() {
            this.newQuiz = true;
            this.newQuizQuestion = "",
                this.newQuizPossibleAnswers = [];
            this.newQuizCorrectAnswer = 0;
        },
        cancelNewQuiz() {
            this.newQuiz = false;
            this.newQuizQuestion = "",
                this.newQuizPossibleAnswers = "";
            this.newQuizCorrectAnswer = 0;
        },
        changeType(id) {
            //  this.openTypeDropdown = false;
            this.Type = id;
            this.changeTypeDropdown(id)
        },
        changeCompletionCondition(id) {
            this.ComplitionCondition = id;
            this.changeComplDropdown(id)
        },
        changeTypeDropdown(id) {
            if (id == 1 || id == "1") {
                this.typeDropdownName = "Wunder"
            } else if (id == 2 || id == "2") {
                this.typeDropdownName = "Quiz"
            } else if (id == 3 || id == "3") {
                this.typeDropdownName = "Standard"
            }else if (id == 4 || id == "4") {
                this.typeDropdownName = "Routenpunkt"
            } else {
                this.typeDropdownName = "Typ auswählen"

            }
        },
        changeComplDropdown(id) {
            if (id == 0 || id == "0") {
                this.complDropdownName = "Visited"
            } else if (id == 1 || id == "1") {
                this.complDropdownName = "Tapped"
            } else if (id == 2 || id == "2") {
                this.complDropdownName = "Viewed"
            } else if (id == 3 || id == "3") {
                this.complDropdownName = "Completed"
            } else {
                this.complDropdownName = "Vorraussetzung"

            }
        },
        closePOI() {

            if (this.town != null) {

                this.$router.push({
                    name: 'TownPOIs',
                    params: {
                        town: this.town
                    }
                })

            } else {
                console.log("tourid:" + this.poi.tour_id)
                console.log("tour:" + this.tour)
                this.$router.push({
                    name: 'POIs',
                    params: {
                        tourId: this.poi.tour_id,
                        tour: this.tour
                    }
                }) // -> /user/123
            }
        },
        parseQuizAnswers(answers) {
                     return answers
            answers = answers.replaceAll("';'", ";");
            answers = answers.replaceAll("[", "");
            answers = answers.replaceAll("]", "");
            answers = answers.replaceAll("'", "");
            answers = answers.replaceAll("'", "");
            answers = answers.replaceAll("\"", "");
            answers = answers.replaceAll("\"", "");
            return answers
        },
        deleteQuiz(id) {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            let apidata = {
                'id': id,
            }

            this.axios.post(this.$backendURL + "quiz/delete", apidata, config).then((response) => {
                this.getQuizzes();
            })
        },
        updatePOI() {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            let apidata = {
                'id': this.poiToEdit,
                'Latidtude': this.poiLatidtude,
                'Longitude': this.poiLongitude,
                'Type': this.Type,
                'Title': this.Title,
                'ComplitionCondition': this.ComplitionCondition,
                'NotificationDistance': this.NotificationDistance,
                'TextBubble':this.TextBubble,
                'POIIndex':this.poiIndex
            }

            this.axios.post(this.$backendURL + "poi/edit", apidata, config).then((response) => {
this.closePOI();
            })

        },
        onChangeAndroidFile(e) {
            this.CustomAndroidFile = e.target.files[0];
            this.saveAndroidFile();
        },
        onChangeIOSFile(e) {
            this.CustomIosFile = e.target.files[0];
            this.saveIOSFile();
        },
        onChangeVideoFile(e) {
            this.VideoFile = e.target.files[0];
            this.saveVideoFile();
        },
        getQuizzes() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            let apidata = {
                'poiid': this.poiToEdit,
            }

            this.axios.post(this.$backendURL + "quiz/all", apidata, config).then((response) => {
                this.Quizzes = response.data.quiz
            })
        },
        saveQuiz() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

         //   var parsedAnswers =  "['" + this.newQuizPossibleAnswers +"']" ;
         //   parsedAnswers = parsedAnswers.replaceAll(";", "';'");
         var parsedAnswers = this.newQuizPossibleAnswers ;
            let apidata = {

                'CorrectAnswerIndex': this.newQuizCorrectAnswer,
                'Question': this.newQuizQuestion,
                'poimetadata_id': this.poiToEdit,
                'PossibleAnswers': parsedAnswers,
            }

            this.axios.post(this.$backendURL + "quiz/create", apidata, config).then((response) => {
                this.newQuiz = false;
                this.newQuizQuestion = "",
                    this.newQuizPossibleAnswers = [];
                this.newQuizCorrectAnswer = 0;
                this.getQuizzes();
            })
        },
        saveAndroidFile() {
            let data = new FormData();
            data.append('file', this.CustomAndroidFile);
            data.append('poiId', this.poiToEdit);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "poi/addandroid", data, config).then((response) => {
                this.CustomObjectAndroidURL = response.data.filename
                console.log(response)

            })

        },

        saveVideoFile() {
            let data = new FormData();
            data.append('file', this.VideoFile);
            data.append('poiId', this.poiToEdit);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "poi/addvideo", data, config).then((response) => {
                this.VideoFileURL = response.data.filename
                console.log(response)

            })

        },
        saveIOSFile() {
            let data = new FormData();
            data.append('file', this.CustomIosFile);
            data.append('poiId', this.poiToEdit);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "poi/addios", data, config).then((response) => {
                this.CustomObjectIOsURL = response.data.filename
                console.log(response)

            })

        },

    },
    computed: {},
}
</script>

<style scoped>
.tourtablecolumn {
    border-right: 1px solid rgba(110, 110, 110, 0.3);
}

.poilist .card {
    margin: 2rem;
}
</style>
