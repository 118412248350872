import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'
import TourOverview from '../views/TourOverview.vue'
import TownOverview from '../views/TownOverview.vue'
import POIOverview from '../views/POIOverview.vue'
import TownPOIOverview from '../views/TownPOIOverview.vue'
import POIDetails from '../views/POIDetails.vue'
import PoiContent from '../views/CustomPOIImageTextList.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Main',
        component: Login
    }, {
        path: '/login',
        name: 'Login',
        component: Login
    }, {
        path: '/tours',
        name: 'Tours',
        component: TourOverview
    }, {
        path: '/towns',
        name: 'Towns',
        component: TownOverview
    }, {
        path: '/pois',
        name: 'POIs',
        component: POIOverview
    }, {
        path: '/poidetails',
        name: 'POIDetails',
        component: POIDetails
    }, {
        path: '/townpois',
        name: 'TownPOIs',
        component: TownPOIOverview
    }, {
        path: '/poicontent',
        name: 'POIContent',
        component: PoiContent
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router