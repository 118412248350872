<template>
<div class="component">

    <div class="columns is-mobile headerrow">
        <div class="column is-2">
            <div class="button is-primary is-large" @click="closePOI()">Zurück</div>
        </div>
        <div class="column is-8">
            <div class="title" v-if="tour!=null">{{tour.TourName}}</div>
        </div>

        <div class="column is-2 ">
            <div class="button is-primary is-large" @click="newPOI=true">Neuen POI</div>
        </div>
    </div>

    <div class="columns is-mobile is-multiline poilist">
        <div class="column is-4" v-for="poi in orderedPOIs">
            <div class="card has-background-success	">

                <div class="card-content">
                    <div class="content has-text-weight-bold is-size-4	">
                        {{poi.Title}}
                    </div>
                </div>
                <footer class="card-footer">
                    <a href="#" class="card-footer-item" @click="openPOI(poi)">Bearbeiten</a>
                    <a href="#" class="card-footer-item" @click="deletePOI(poi)">Löschen</a>
                </footer>
            </div>
        </div>
    </div>

    <div class="modal" :class="{'is-active':newPOI}">
        <div class=" modal-background" @click="closeModal()"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Neuer POI</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">

                <div class="columns is-mobile is-multiline">
                    <div class="column is-3">Titel</div>
                    <div class="column is-9"><input v-model="Title" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Typ</div>
                    <div class="column is-9">

                        <div class="dropdown " @click="openTypeDropdown=!openTypeDropdown" :class="{'is-active':openTypeDropdown}">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                    <span>{{typeDropdownName}}</span>
                                    <span class="icon is-small">
                                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">

                                    <a href="#" @click="changeType(1)" :class="{'is-active':Type==1}" class="dropdown-item">
                                        Wunder
                                    </a>
                                    <a href="#" @click="changeType(2)" :class="{'is-active':Type==2}" class="dropdown-item ">
                                        Quiz
                                    </a>
                                    <a href="#" @click="changeType(3)" :class="{'is-active':Type==3}" class="dropdown-item ">
                                        Standard
                                    </a>
                                    <a href="#" @click="changeType(4)" :class="{'is-active':Type==4}" class="dropdown-item ">
                                        Routenpunkt
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="column is-3">ComplitionCondition</div>
                    <div class="column is-9">

                        <div class="dropdown " @click="openCompletionDropdown=!openCompletionDropdown" :class="{'is-active':openCompletionDropdown}">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                                    <span>{{complDropdownName}}</span>
                                    <span class="icon is-small">
                                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">

                                    <a href="#" @click="changeCompletionCondition(0)" :class="{'is-active':ComplitionCondition==0}" class="dropdown-item">
                                        Visited
                                    </a>
                                    <a href="#" @click="changeCompletionCondition(1)" :class="{'is-active':ComplitionCondition==1}" class="dropdown-item">
                                        Tapped
                                    </a>
                                    <a href="#" @click="changeCompletionCondition(2)" :class="{'is-active':ComplitionCondition==2}" class="dropdown-item">
                                        Viewed
                                    </a>
                                    <a href="#" @click="changeCompletionCondition(3)" :class="{'is-active':ComplitionCondition==3}" class="dropdown-item">
                                        Completed
                                    </a>

                                </div>
                            </div>
                        </div>

                    </div>
                    <br><br><br><br><br>
                    <br><br><br><br><br>

                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="createPOI()">Speichern</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'POI',
    props: [],
    mounted: function () {
        if (!this.$route.params.tour) {
            this.$router.push({
                name: 'Towns'
            })

        } else {
            this.tourId = this.$route.params.tour.id
            this.townId = this.$route.params.townId
            this.tour = this.$route.params.tour
            this.getAllPOIs();
        }

    },
    data() {
        return {
            newPOI: false,
            allPOIs: [],
            isInEditMode: false,

            Title: "",
            Type: 2,
            ComplitionCondition: 1,
            TextList: "",
            ImageURLsList: "",
            VideoURL: "",
            CustomObjectIOsURL: "",
            CustomObjectAndroidURL: "",
            tourId: 0,
            poiToEdit: 0,
            tour: null,
            townId: null,
            openTypeDropdown: false,
            openCompletionDropdown: false,
            typeDropdownName: "Typ auswählen",
            complDropdownName: "Vorraussetzung"
        }
    },
    methods: {
        onChange(e) {
            this.file = e.target.files[0];
        },
        closePOI() {
            console.log("tourid:" + this.tour.id)

            this.$router.push({
                name: 'Tours',
                params: {
                    townId: this.townId
                }
            })
        },
        openEditMode(poi) {
            this.Title = poi.Title
            this.Type = poi.Type;
            this.ComplitionCondition = poi.ComplitionCondition;
            this.TextList = poi.TextList;
            this.ImageURLsList = poi.ImageURLsList;
            this.VideoURL = poi.VideoURL;
            this.CustomObjectIOsURL = poi.CustomObjectIOsURL;
            this.CustomObjectAndroidURL = poi.CustomObjectAndroidURL;
            this.isInEditMode = true;
            this.newPOI = true;
            this.poiToEdit = poi.id;
            this.changeTypeDropdown(poi.Type)
            this.changeComplDropdown(this.ComplitionCondition)

        },
        changeType(id) {
            //  this.openTypeDropdown = false;
            this.Type = id;
            this.changeTypeDropdown(id)
        },
        changeCompletionCondition(id) {
            this.ComplitionCondition = id;
            this.changeComplDropdown(id)
        },
        changeTypeDropdown(id) {
            if (id == 1 || id == "1") {
                this.typeDropdownName = "Wunder"
            } else if (id == 2 || id == "2") {
                this.typeDropdownName = "Quiz"
            } else if (id == 3 || id == "3") {
                this.typeDropdownName = "Standard"
            } else if (id == 4 || id == "4") {
                this.typeDropdownName = "Routenpunkt"
            } else {
                this.typeDropdownName = "Typ auswählen"

            }
        },
        changeComplDropdown(id) {
            if (id == 0 || id == "0") {
                this.complDropdownName = "Visited"
            } else if (id == 1 || id == "1") {
                this.complDropdownName = "Tapped"
            } else if (id == 2 || id == "2") {
                this.complDropdownName = "Viewed"
            } else if (id == 3 || id == "3") {
                this.complDropdownName = "Completed"
            } else {
                this.complDropdownName = "Vorraussetzung"

            }
        },
        closeModal() {
            this.Title = ""
            this.Type = ""
            this.ComplitionCondition = ""
            this.TextList = ""
            this.ImageURLsList = 0
            this.VideoURL = 0
            this.CustomObjectIOsURL = true
            this.CustomObjectAndroidURL = "de"
            this.file = null;
            this.isInEditMode = false;
            this.newPOI = false;
            this.poiToEdit = 0;

        },
        getAllPOIs() {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'tour_id': this.tourId
            }

            this.axios.post(this.$backendURL + "poi/all", apidata, config).then((response) => {
                this.allPOIs = response.data.pois
            })
        },
        saveImage(id) {
            let data = new FormData();
            data.append('file', this.file);
            data.append('tourid', id);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "tour/changeImage", data, config).then((response) => {
                console.log(response)
                this.closeModal();
                this.getAllTours();
            })

        },
        openPOI(poi) {
            this.$router.push({
                name: 'POIDetails',
                params: {
                    poi: poi,
                    tour: this.tour
                }
            }) // -> /user/123

        },
        deletePOI(poi) {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'id': poi.id
            }

            this.axios.post(this.$backendURL + "poi/delete", apidata, config).then((response) => {
                this.getAllPOIs();
            })
        },
        createPOI() {

            if (this.Title == "") {
                return;
            }
            let newPOIData = {
                'Title': this.Title,
                'Type': this.Type,

                'ComplitionCondition': this.ComplitionCondition,
                'tour_id': this.tourId,

            }

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            if (this.isInEditMode) {
                newPOIData.id = this.poiToEdit;
                this.axios.post(this.$backendURL + "poi/edit", newPOIData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.tour.id)

                    } else {
                        this.closeModal();
                        this.getAllPOIs();
                    }

                })
            } else {
                this.axios.post(this.$backendURL + "poi/create", newPOIData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.tour.id)
                    } else {
                        this.closeModal();
                        this.getAllPOIs();
                    }
                })
            }

        }
    },
    computed: {
        orderedPOIs: function () {
            return this.allPOIs.sort((a, b) => parseFloat(a.POIIndex) - parseFloat(b.POIIndex));
        }
    }
}
</script>

<style scoped>
.tourtablecolumn {
    border-right: 1px solid rgba(110, 110, 110, 0.3);
}

.poilist .card {
    margin: 2rem;
}
</style>
