<template>
<div class="logincomponent">
    <div class="columns is-mobile is-multiline">
        <div class="column is-8 is-offset-2"><input v-model="usermail" class="input is-primary" type="text" placeholder="Email">
        </div>
        <div class="column is-8 is-offset-2"><input v-model="userpassword" class="input is-primary" type="password" placeholder="Passwort">
        </div>
        <div class="column is-6 is-offset-3 button is-primary" @click="login()">Login</div>
    </div>

</div>
</template>

<script>
export default {
    name: 'Login',
    props: [],
    mounted: function () {

    },
    data() {
        return {
            msg: 'Welcome to Your Vue.js App',
            data: [],
            usermail: "",
            userpassword: ""
        }
    },
    methods: {
        login() {
            if (this.usermail != "" && this.userpassword != "") {
                let postData = {
                   'password': this.userpassword,
                    'email': this.usermail
                }
                this.axios.post(this.$backendURL + "auth/login", postData).then((response) => {
                    console.log(response.data)
                    
                localStorage.setItem('bearertoken', response.data.access_token);
                            this.$router.push({ name: 'Towns' }) // -> /user/123

                })
            }
        }
    },
    computed: {},
}
</script>

<style scoped>

</style>
