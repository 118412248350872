<template>
<div class="component">

    <div class="columns is-mobile headerrow">
        <div class="column is-2">
            <div class="button is-primary is-large" @click="closeTours()">Zurück</div>
        </div>
        <div class="column is-8">
            <div class="title"></div>
        </div>

        <div class="column is-2 ">
            <div class="button is-primary is-large" @click="newTour=true">Neue Tour</div>
        </div>
    </div>

    <div>
        <div v-for="tour in allTours" class="columns is-mobile is-multiline">
            <div class="column is-1"><img v-if="tour.PromoImageURL.length>5" class="previewImage" :src="$storageURL + tour.PromoImageURL"></div>

            <div class="column is-2 is-size-4 tourtablecolumn">{{tour.TourName}}</div>
            <div class="column is-2 is-size-4 tourtablecolumn"> <span v-if="tour.IsBeta">Beta</span></div>
            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openPOIs(tour)">POIs</div>
            </div>
            <div class="column is-2 is-size-3 tourtablecolumn">
                <div class="button is-large is-primary" @click="openEditMode(tour)">Bearbeiten</div>
            </div>
            <div class="column is-2 is-size-4 tourtablecolumn">
                <div class="button is-large is-danger" @click="deleteTour(tour)">Löschen</div>
            </div>
        </div>

    </div>

    <div class="modal" :class="{'is-active':newTour}">
        <div class=" modal-background" @click="closeModal()"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Neue Tour</p>
                <button class="delete" aria-label="close" @click="closeModal()"></button>
            </header>
            <section class="modal-card-body">

                <div class="columns is-mobile is-multiline">
                    <div class="column is-3">Tourname</div>
                    <div class="column is-9"><input v-model="newTourName" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Kurzbeschreibung</div>
                    <div class="column is-9"><input v-model="newTourShortDescription" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Distanz</div>
                    <div class="column is-9"><input v-model="newTourEstimatedDistance" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Dauer</div>
                    <div class="column is-9"><input v-model="newTourEstimatedLength" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Latitude</div>
                    <div class="column is-9"><input v-model="newTourLatidtude" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-3">Longitude</div>
                    <div class="column is-9"><input v-model="newTourLongitude" class="input is-primary" type="text" placeholder=""></div>

                                <div class="column is-3">NotificationDistance</div>
            <div class="column is-9"><input v-model="NotificationDistance" class="input is-primary" type="text" placeholder=""></div>

                    <div class="column is-12"> <label class="checkbox">
                            <input v-model="newTourIsBeta" type="checkbox">
                            Beta
                        </label></div>
                    <div class="column is-3">Media</div>
                    <div class="column is-9"><input type="file" class="form-control" v-on:change="onChange"></div>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="createTour()">Speichern</button>
                <button class="button" @click="closeModal()">Abbrechen</button>
            </footer>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'Tour',
    props: [],
    mounted: function () {
        if (!this.$route.params.townId) {
            this.$router.push({
                name: 'Towns'
            })

        } else {
            this.townId = this.$route.params.townId
            this.getAllTours();
        }

    },
    data() {
        return {
            newTour: false,
            allTours: [],
            isInEditMode: false,

            newTourName: "",
            newTourShortDescription: "",
            newTourEstimatedDistance: "",
            newTourEstimatedLength: "",
            newTourLongitude: "",
            newTourLatidtude: "",
            newTourIsBeta: true,
            newTourLanguage: "de",
            townId: 0,
            file: '',
            tourToEdit: 0,
            NotificationDistance:"",

        }
    },
    methods: {
        onChange(e) {
            this.file = e.target.files[0];
        },
        openPOIs(tour) {
            this.$router.push({
                name: 'POIs',
                params: {
                    tour: tour,
                    townId:this.townId
                }
            })

        },
        closeTours() {
            this.$router.push({
                name: 'Towns'
            })
        },
        openEditMode(tour) {
            this.newTourName = tour.TourName
            this.newTourShortDescription = tour.ShortDescription;
            this.newTourEstimatedDistance = tour.EstimatedDistance;
            this.newTourEstimatedLength = tour.EstimatedLength;
            this.newTourLongitude = tour.Longitude;
            this.newTourLatidtude = tour.Latidtude;
            this.newTourIsBeta = tour.IsBeta;
            this.isInEditMode = true;
            this.newTour = true;
            this.tourToEdit = tour.id;
            this.NotificationDistance = tour.NotificationDistance
        },
        closeModal() {
            this.newTourName = ""
            this.newTourShortDescription = ""
            this.newTourEstimatedDistance = ""
            this.newTourEstimatedLength = ""
            this.newTourLongitude = 0
            this.newTourLatidtude = 0
            this.newTourIsBeta = true
            this.file = null;
            this.isInEditMode = false;
            this.newTour = false;
            this.tourToEdit = 0;
            this.NotificationDistance =""

        },
        getAllTours() {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {

                'town_id': this.townId
            }

            this.axios.post(this.$backendURL + "tour/all", apidata, config).then((response) => {
                this.allTours = response.data.tours
            })
        },
        deleteTour(tour) {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {

                'id': tour.id
            }

            this.axios.post(this.$backendURL + "tour/delete", apidata, config).then((response) => {
                this.getAllTours();
            })
        },
        saveImage(id) {
            let data = new FormData();
            data.append('file', this.file);
            data.append('tourid', id);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "tour/changeImage", data, config).then((response) => {
                console.log(response)
                this.closeModal();
                this.getAllTours();
            })

        },
        createTour() {
            if (this.newTourName == "") {
                return;
            }
            let newTourData = {
                'TourName': this.newTourName,
                'ShortDescription': this.newTourShortDescription,
                'NotificationDistance':this.NotificationDistance,

                'EstimatedDistance': this.newTourEstimatedDistance,
                'EstimatedLength': this.newTourEstimatedLength,
                'Longitude': this.newTourLongitude,
                'Latidtude': this.newTourLatidtude,
                'IsBeta': this.newTourIsBeta,
                'town_id': this.townId
            }

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };

            if (this.isInEditMode) {
                newTourData.id = this.tourToEdit;
                this.axios.post(this.$backendURL + "tour/edit", newTourData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.tour.id)

                    } else {
                        this.closeModal();
                        this.getAllTours();
                    }

                })
            } else {
                this.axios.post(this.$backendURL + "tour/create", newTourData, config).then((response) => {
                    if (this.file) {
                        this.saveImage(response.data.tour.id)

                    } else {
                        this.closeModal();
                        this.getAllTours();
                    }

                })
            }

        }
    },
    computed: {},
}
</script>

<style scoped>
.tourtablecolumn {
    border-right: 1px solid rgba(110, 110, 110, 0.3);
}

.previewImage {
    max-height: 4rem;
}
</style>
