<template>
<div class="component">
    <div class="columns is-mobile">
        <div class="column is-2">
            <div class="button is-primary is-large" @click="closePOI()">Zurück</div>
        </div>
        <div class="column is-8">
            <div class="title" v-if="poi!=null">{{poi.Title}}</div>
        </div>
        <div class="column is-2 ">
            <div class="button is-primary is-large" @click="createContentObject()">Neue Seite</div>
        </div>
    </div>

    <div v-if="poiContentList!=null">
        <div v-for="content in poiContentList" class="columns is-mobile is-multiline poicontentobject">
            <div class="column is-4 is-size-4 breakword"><p class="customcontentheader">Text:</p> {{content.Textcontent}}</div>

            <div class="column is-3 is-size-4 breakword" v-if="poi.Type!=1"><p class="customcontentheader">Bild:</p> {{content.Imageurl}}
            </div>

            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openChangeContentText(content)" >Text bearbeiten</div>
            </div>

            <div class="column is-2 is-size-4">
                <div class="button is-large is-primary" @click="openChangeContentImage(content)" v-if="poi.Type!=1">Bild bearbeiten</div>
            </div>
    <div class="column is-1 is-size-4">
                <div class="button is-large is-danger" @click="deleteContent(content.id)">Löschen</div>
            </div>
        </div>

    </div>

    <div class="modal" :class="{'is-active':openEditText}">
        <div class=" modal-background" @click="resetView()"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Text bearbeiten</p>
                <button class="delete" aria-label="close" @click="resetView()"></button>
            </header>
            <section class="modal-card-body">

                <div class="columns is-mobile is-multiline">
                    <div class="column is-3">Textcontent</div>
                    <div class="column is-9"><input v-model="editedContentTitle" class="input is-primary" type="text" placeholder=""></div>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="updateContentTitle()">Speichern</button>
                <button class="button" @click="resetView()">Abbrechen</button>
            </footer>
        </div>
    </div>

    <div class="modal" :class="{'is-active':openEditImage}">
        <div class=" modal-background" @click="resetView()"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title" >Bild bearbeiten</p>
                <button class="delete" aria-label="close" @click="resetView()"></button>
            </header>
            <section class="modal-card-body">

                <div class="columns is-mobile is-multiline">
                    <div class="column is-3">Bild</div>
                    <div class="column is-9"><input type="file" class="form-control" v-on:change="onChangeVideoFile"></div>
                </div>

            </section>
            <footer class="modal-card-foot">
                <button class="button is-success" @click="saveContentFile()">Bild löschen</button>
                <button class="button" @click="resetView()">Abbrechen</button>
            </footer>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'POI',
    props: [],
    mounted: function () {
        if (!this.$route.params.poi) {
            this.$router.push({
                name: 'Towns'
            })

        }
        this.poi = this.$route.params.poi
        this.tour = this.$route.params.tour
        this.town = this.$route.params.town
        this.Title = this.poi.Title
        this.poiId = this.poi.id
        this.getAllContentObjects();
    },
    data() {
        return {
            poi: null,
            town:null,
            tour:null,
            poiId: null,
            Title: "",
            poiContentList: null,
            selectedPOIContent: -1,
            openEditText: false,
            openEditImage: false,
            editedContentTitle: "",
            poiImageFile: null,
        }
    },

    methods: {
        closePOI() {
            this.$router.push({
                name: 'POIDetails',
                params: {
                    poi: this.poi,
                    tour:this.tour,
                    town:this.town
                }
            }) // -> /user/123
        },

        onChangeVideoFile(e) {
            this.poiImageFile = e.target.files[0];
            this.saveContentFile();
        },
        resetView() {
            this.openEditText = false;
            this.openEditImage = false;
            this.selectedPOIContent = -1;
            this.editedContentTitle = "";
            this.poiImageFile = null
        },
        openChangeContentText(content) {
            this.selectedPOIContent = content.id;
            this.editedContentTitle = content.Textcontent

            this.openEditText = true;
        },
        openChangeContentImage(content) {
            this.selectedPOIContent = content.id;

            this.openEditImage = true;
        },
        getAllContentObjects() {

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'poimetadata_id': this.poiId
            }

            this.axios.post(this.$backendURL + "poicontent/all", apidata, config).then((response) => {
                this.poiContentList = response.data.poicontent
            })
        },

        createContentObject() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'poimetadata_id': this.poiId
            }

            this.axios.post(this.$backendURL + "poicontent/create", apidata, config).then((response) => {
                this.getAllContentObjects();
                this.resetView();
            })
        },

            deleteContent(contentid) {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'id':contentid
            }

            this.axios.post(this.$backendURL + "poicontent/delete", apidata, config).then((response) => {
                this.getAllContentObjects();
                this.resetView();
            })
        },

        updateContentTitle() {
            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken
                }
            };
            let apidata = {
                'id': this.selectedPOIContent,
                'Textcontent': this.editedContentTitle,

            }

            this.axios.post(this.$backendURL + "poicontent/edittext", apidata, config).then((response) => {
                                this.resetView();

                this.getAllContentObjects();
            })
        },

        saveContentFile() {
            let data = new FormData();
            data.append('file', this.poiImageFile);
            data.append('id', this.selectedPOIContent);

            const config = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.bearertoken,
                    'content-type': 'multipart/form-data'

                }
            };

            this.axios.post(this.$backendURL + "poicontent/editfile", data, config).then((response) => {
                this.resetView();
   this.getAllContentObjects();
            })

        },

    },
    computed: {},
}
</script>

<style scoped>
.tourtablecolumn {
    border-right: 1px solid rgba(110, 110, 110, 0.3);
}

.poilist .card {
    margin: 2rem;
}

.breakword{    word-break: break-word;}

.customcontentheader{
    font-weight:bolder;
}

.poicontentobject{
    border-bottom: 1px solid rgba(1,1,1,0.2);
    width:96%;
    margin:auto;
}
</style>
